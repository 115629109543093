let memberData = {
    groups:[
        {
            id:"EventGeneralCoordinator",
            groupsName:'總副召',
            members:[
                "ADT109107"
            ]
        },
        {
            id:"Event",
            groupsName:'企劃組',
            members:[
                
            ]
        },
        {
            id:"Artistic",
            groupsName:'美宣組',
            members:[
                
            ]
        },
        {
            id:"PublicRelations",
            groupsName:'公關組',
            members:[

            ]
        },
        {
            id:"Multimedia",
            groupsName:'多媒體組',
            members:[

            ]
        },
        {
            id:"Photography",
            groupsName:'攝影組',
            members:[

            ]
        },
        {
            id:"Equipment",
            groupsName:'器材組',
            members:[

            ]
        },
        {
            id:"GeneralAffairs",
            groupsName:'總務組',
            members:[

            ]
        },
    ]
}

export default memberData;