<template>
    
    <section class="footer py-4 py-md-5 bg-light text-secondary">
        <div class="container py-4 py-md-5 px-4 px-md-3">
            <div class="row">

                <div class="col-lg-3 mb-3">
                    <div class="logoDiv">
                        <a class="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="../">
                            <li class="material-icons">chat</li>
                            <div class="LogoText"></div>
                        </a>
                    </div>

                    <ul class="list-unstyled small text-muted">
                        <li class="mb-2">國立臺中教育大學</li>
                        <li class="mb-2">數位內容科技學系113級畢籌團隊</li>
                        <li class="mb-2">2022(C) All Copyright Reserved.</li>
                        <li class="mb-2">Designed by Group DCT</li>
                    </ul>
                
                </div>

                <div class="col-6 col-lg-2 mb-3">
                    <h5>Links</h5>
                    <ul class="list-unstyled">
                        <li class="mb-2" v-for="item in route" :key="item" onclick="window.scrollTo({top:0})">
                            <router-link :to="item.link">{{ item.name }}</router-link>
                        </li>
                        <!-- <li class="mb-2"><router-link to="/member">策展團隊</router-link></li>
                        <li class="mb-2"><a href="../pages/terms.php">各組作品</a></li>
                        <li class="mb-2"><a href="../pages/contact.php">人氣投票</a></li>
                        <li class="mb-2"><a href="../pages/contact.php">周邊</a></li> -->
                    </ul>
                </div>

                <div class="col-6 col-lg-2 mb-3">
                    <h5>Guides</h5>
                    <ul class="list-unstyled">
                        <li class="mb-2"><a href="../pages/doc.php#FAQ">F&Q</a></li>
                    </ul>

                </div>

                <div class="col-6 col-lg-2 mb-3">
                    <h5>Group</h5>
                    <ul class="list-unstyled">
                        <li class="mb-2" v-for="(item, index) in groups" :key="index"><router-link :to="{name:'member', params:{ group:item.id} }">{{ item.groupsName }}</router-link></li>
                        <!-- <li class="mb-2"><router-link :to="{name:'member', params:{ group:'EventGeneralCoordinator'} }">總副召</router-link></li>
                        <li class="mb-2"><router-link :to="{name:'member', params:{ group:'Event'} }">企劃組</router-link></li>
                        <li class="mb-2"><router-link :to="{name:'member', params:{ group:'Artistic'} }">美宣組</router-link></li>
                        <li class="mb-2"><router-link :to="{name:'member', params:{ group:'PublicRelations'} }">公關組</router-link></li>
                        <li class="mb-2"><router-link :to="{name:'member', params:{ group:'Multimedia'} }">多媒體組</router-link></li> -->
                    </ul>

                </div>

                <div class="col-6 col-lg-2 mb-3">
                    <h5>Social Media</h5>
                    <ul class="list-unstyled">
                        <li class="mb-2"><a href="https://www.youtube.com/@ntcu-dct-113"><i class="fa fa-youtube-play"></i> YouTube</a></li>
                        <li class="mb-2"><a href="https://www.instagram.com/ntcudct113_he11o.liv3r/"><i class="fa fa-instagram"></i> Instagram</a></li>
                        <li class="mb-2"><a href="https://www.facebook.com/profile.php?id=100086739893931"><i class="fa fa-facebook"></i> Facebook</a></li>
                        <!-- <li class="mb-2"><a href="javascript:alert('其實我們還沒創')"><i class="fa fa-linkedin"></i> Linkedin</a></li> -->
                    </ul>

                </div>
            </div>
        </div>
    </section>

</template>

<script setup>
import { reactive } from 'vue';
import { useStore } from 'vuex';
import memberData from '@/assets/data/members.js';

const store = useStore();

const route = reactive(store.state.route);

const groups = reactive(memberData.groups)


</script>

<style scoped>

.footer .mb-2 a{
    color: rgba(90, 90, 90, 1);
    text-decoration: none;
}

.footer .mb-2 a:hover{
    color: rgba(120, 120, 120, 1);
    text-decoration: none;
}

</style>