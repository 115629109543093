<template>

    <div class="scroller">

		<span class="switchButton prev" @click="slide(-1)">《</span>
		<span class="switchButton next" @click="slide(1)">》</span>

		<!-- <div class="container"> -->

			<div class="SlideDisplayer" ref="Displayer">
				<div class="scrollDiv" ref="scrollDiv">
					<slot></slot>
				</div>
			</div>
		<!-- </div> -->
    </div>
</template>

<script setup>
import { ref, onMounted} from 'vue';

// const props = defineProps({
//     maxPressTimes:{
//         type: Number,
//         default: 10000
//     },
//     imgHeight:{
//         type: String
//     }
// })

// const DisplaySlideTranslate = ref(0);

const Displayer = ref();

const scrollDiv = ref();

// const currentPosition = ref(0);

onMounted(()=>{


    
    setTimeout(() => {
        console.log(Displayer.value.offsetWidth);
    }, 1000);
})

const slide = (val)=>{
	// let elements = Displayer.value.querySelectorAll(".scrollDiv > *")
	// let eleNum = elements.length;
	// currentPosition.value = (currentPosition.value + val + eleNum) % eleNum;
	// console.log(currentPosition.value)
	// console.log(elements)
	// elements.forEach((item)=>{
	// 	console.log(item.offsetLeft)
	// })
	// scrollDiv.value.scrollTo({left: elements[currentPosition.value].offsetLeft ,behavior: "smooth"})

	// elements[currentPosition.value].scrollIntoView();

	let element = Displayer.value.querySelector(".scrollDiv > *");
	let elementWidth = element.offsetWidth;

	// console.log(scrollDiv.value.scrollLeft + elementWidth*val)
	scrollDiv.value.scrollTo({left: scrollDiv.value.scrollLeft + elementWidth*val ,behavior: "smooth"})
    // let temp = Math.abs(DisplaySlideTranslate.value+val*200);
    // if(temp < Displayer.value.offsetWidth && DisplaySlideTranslate.value+val*200 <= 0)
    //     DisplaySlideTranslate.value += val*200;
}

</script>

<style>

.scroller{
	position: relative;
    /* overflow-x: hidden; */
}

.scroller .switchButton{
	position: absolute;
	font-size: 2rem;
	color: white;
	display: block;
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	text-align: center;
	cursor: pointer;
	user-select: none;
	z-index: 1;
}

.scroller .switchButton.prev{
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.scroller .switchButton.next{
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.SlideDisplayer{
    position: absolute;
    left: 0;
	height: 100%;
	width: 100%;
	overflow: auto;
	/* overflow: hidden; */
	/* transform: translateX(0); */
	/* transition: 0.5s; */
}

.SlideDisplayer .scrollDiv{
	position: relative;
	overflow: scroll;
	overflow-y: hidden;
	height: 100%;
	width: 100%;
	display: flex;
	scroll-snap-type: x mandatory;
}

.SlideDisplayer .scrollDiv > *{
	scroll-snap-align: start;
}

.SlideDisplayer .scrollDiv::-webkit-scrollbar{
	width: 0;
}

</style>