import { createStore } from "vuex";

const store = createStore({
	state() {
		return {
			sidebarIndex: 0,
			route: [
				{name: '首　頁', link: '/'},
				{name: '策展團隊', link: '/member'},
				{name: '各組作品', link: '/works'},
				{name: '人氣投票', link: '/vote'},
				{name: '周邊商品', link: '/'},
				// {name: '提報回饋', link: '/'}                
			],
		};
	},
	mutations: {
		setSidebarIndex(state, value) {
			state.sidebarIndex = value;
			// console.log(state.sidebarIndex)
		},
	},
	actions:{
		setSidebarIndex(con, value){
			con.commit("setSidebarIndex", value);
		}
	},
	getters:{
		indexNow(state){
			console.log(state.sidebarIndex)
			return state.sidebarIndex
		}
	},
	modules:{
		
	}
});

export default store