<template>

    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
    </metainfo>

    <div>
        
        <div class="introContainer" style="position: relative;">
            <section class="intro">

                <!-- <img src="@/assets/img/shatter/B.png" class="shatters" :style="{'--d': Math.random()*2}">
                <img src="@/assets/img/shatter/Y.png" class="shatters" :style="{'--d': Math.random()*2}">
                <img src="@/assets/img/shatter/P.png" class="shatters" :style="{'--d': Math.random()*2}"> -->

                <!-- <div class="introBackground"></div> -->

                <!-- <div class="logoContainer">

                    <span class="img">
                        <img style="width: 100%" src="@/assets/img/logo_2.png">
                    </span>

                </div> -->

                <!-- <img class="introCharacter" src="@/assets/img/introCharacter.png"> -->

            </section>
            <div class="PlanB">
                <div style="margin: 2rem;">
                    <img style="width: 100%; aspect-ratio: 16/9;" src="@/assets/img/intro.png" alt="game pic">
                    <br>
                    <div style="color: white; font-size: 1.8rem;" class="mt-5">罣礙 CARE</div>
                    <div style="color: lightgray; font-size: 1rem;" class="mb-2">遊戲下載</div>
                    <select v-model="selectVersion" style="width: 100%; background: transparent; border: 1px solid white; padding: .5rem .3rem; color: white;">
                        <option value="0">Windows版</option>
                        <option value="1">Mac版</option>
                    </select>
                    <hr style="border: 1px solid white;">
                    <button class="downloadbtn">{{ selectVersion==0?"下載 Windows 版":"目前尚沒有 Mac 版" }}</button>
                </div>
            </div>
        </div>

        <section class="news mt-5" style="display: flex; justify-content: center;">

            <div style="width: 100%; max-width: 1000px; padding: 0 .5rem;">
                <sectionTitle title="罣礙 CARE" style="" />
                <div style="color: gray; font-size: 1rem;">A horror puzzle game</div>
                <hr style="margin-top: 5rem; border: 1px solid white;">

                <div style="display: grid; grid-template-columns: 3fr 2fr; gap: 2rem;">
                    <div>
                        <sectionTitle title="關於本遊戲" style="font-size: 1.6rem;" />
                        <br>
                        <iframe style="width: 100%; aspect-ratio: 16/9" src="https://www.youtube.com/embed/O3oLfT7D2lk?si=4Qnl0J_pCSzNdsLq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div style="font-size: .9rem; color: white; font-weight: 100; display: flex; flex-direction: column; justify-content: space-between;">
                        <div style="line-height: 2rem;">
                            <p>簡介:</p>
                            <p>《罣礙》是一款融合解謎和劇情探索的驚悚遊戲，它融入了許多台灣傳統信仰的元素，賦予了遊戲獨樹一幟的文化底蘊。當你步步追查令人毛骨悚然的神秘事件，這些傳統也可能成為你逃離恐懼的關鍵所在。</p>
                        </div>
                        <div>
                            發行時間: 未定<br>
                            製作團隊: 中教大 113級數位系 第六組<br>
                        </div>
                    </div>
                </div>

                <div>
                    <buttonScroller maxPressTimes="8" style="height: 200px; margin-top: 5rem;">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(1).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(2).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(3).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(4).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(5).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(6).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(7).png">
                        <img style="height: 200px;" src="@/assets/img/demopic/pic(8).png">
                    </buttonScroller>
                </div>

            </div>

            <!-- <div class="displayer">

                <buttonScroller maxPressTimes="3" style="height: 50vh;">
                    <cardT v-for="(data, index) in newsData" :key="index" :title="data.title" :date="data.date" :href="data.href" :imgSrc="data.imgSrc" :infoType="data.infoType" :newNews="data.newNews">
                        <div v-html="data.info">
                        </div>
                    </cardT>
                </buttonScroller>

            </div> -->
        </section>

        <section style="display: flex; justify-content: center; margin: 5rem 0;">
            <div style="border-radius: 4px; background: rgb(22, 28, 33); max-width: 1000px; color: white; padding: 2rem">
                <div style="font-size: 1.6rem;">系統需求</div>
                <div style="color: lightgray; padding-left: 4px; font-size: .8rem;">
                    <i class="fa fa-windows"></i> Windows
                </div>

                <hr style="border: 1px solid white;">

                <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem; line-height: 2.6rem;">
                    <div>
                        最低配備<br>

                        作業系統: Windows 10 (64-bit) / Windows 11 64-bit<br>

                        處理器: 4核 3 GHz 處理器<br>

                        記憶體: 8 GB 記憶體<br>

                        顯示卡: NVIDIA GTX 750Ti<br>

                        儲存空間: 2 GB 可用空間<br>

                        音效卡: Integrated<br>
                    </div>

                    <div>
                        建議配備<br>

                        作業系統: Windows 10 (64-bit) / Windows 11 64-bit<br>

                        處理器: Intel Core i5<br>

                        記憶體: 16 GB 記憶體<br>

                        顯示卡: NVIDIA GeForce GTX 1060<br>

                        儲存空間: 8 GB 可用空間<br>

                        音效卡: Integrated<br>

                    </div>
                </div>

            </div>
        </section>

        <!-- <introCharacterSection />

        <introVideoDisplay /> -->


    </div>
</template>

<script setup>
// @ is an alias to /src
// import splitLine from '@/components/splitLine/splitLine.vue'
import sectionTitle from '@/components/sectionTitle/sectionTitle.vue'
// import cardT from '@/components/card/cardT.vue'
// import horizenScroll from '@/components/horizenScroll/horizenScroll.vue'
import buttonScroller from '@/components/buttonScroller/buttonScroller.vue'
// import introCharacterSection from '@/components/introCharacterSection/introCharacterSection.vue'
// import introVideoDisplay from '@/components/introVideoDisplay/introVideoDisplay.vue'
// import shatterC from '@/components/shatter/shatterC.vue';
// import footerBar from '@/components/footerBar/footerBar.vue'
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

// const introVideo_Y = ref(0);
// const scrollPos = ref(0);
// const introCharacter_Y = ref(1100);
// const moveLenght = ref(5);

const store = useStore();
const selectVersion = ref(0);
// let introVideo_YInterval;

onMounted(()=>{
    window.addEventListener("scroll", onScroll)
    // introVideo_YInterval = setInterval(() => {
    //     introVideo_Y.value = document.querySelector('.introVideo').getBoundingClientRect().top + window.scrollY;
    // }, 2000);
    store.dispatch("setSidebarIndex", 0);

    setTimeout(() => {
        document.querySelector(".introAnimation").classList.add("active")
    }, 500);

})

onBeforeUnmount(()=>{
    window.removeEventListener("scroll", onScroll)
    // clearInterval(introVideo_YInterval);
})

const onScroll = ()=>{
    // let y = window.scrollY;
    // if(y < introCharacter_Y.value){
        //document.querySelector('.intro .bar').style.top = 'calc(40vh + ' + y*1.05 + 'px)';
    // }
    
    // if(y >= introVideo_Y.value + 90*moveLenght.value){
    //     //document.querySelector('.introVideo').style.setProperty('--r', 90 - ((y - introVideo_Y) / moveLenght) + 'deg')
    //     document.querySelector('.introVideo h2').classList.remove("active");
    //     document.querySelector(".introVideoSection .dark").classList.remove("active");
    // }else if(y < introVideo_Y.value){
    //     //document.querySelector('.introVideo').style.setProperty('--r', 90 + 'deg')
    //     document.querySelector('.introVideo h2').classList.remove("active");
    //     document.querySelector(".introVideoSection .dark").classList.remove("active");

    // }else if(y > introVideo_Y.value){
    //     document.querySelector('.introVideo h2').classList.add("active");
    //     document.querySelector(".introVideoSection .dark").classList.add("active");

    // }

    // document.querySelectorAll(".floating").forEach(function(item){
    //     item.style.transform = 'translate(0, calc(' + (y-(item.getBoundingClientRect().top+window.scrollY))*-0.1 + 'px * var(--s)))'
    // })
}



</script>

<style src="@/assets/css/Home.css">

</style>