<template>
    <!-- <h2 class="sectionTitle">{{ title }}</h2> -->
    <span style="color: white; font-size: 3rem;">{{ title }}</span>
</template>

<script>

export default {
    name: 'sectionTitle',
    props:{
        title:{
            type: String,
            require: true
        }
    }
}
</script>

<style scoped>
.sectionTitle{
    position: relative;
    color: rgba(60, 60, 60, 1);
    -webkit-text-stroke: #cacaca 2px;
    font-size: 4rem;
    opacity: 1;
    user-select: none;
    transition: 0.5s;
    background: url("./slash.png");
}
</style>